import * as React from "react";
import { FunctionComponent, useState, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import {
  PortableText,
  SanityCtaLabelsInterface,
  SanityErrorMessagesLabelsInterface,
  SanityFormsLabelsInterface
} from "web-common/src/types/SanityTypes";
import { isValidEmail } from "web-common/src/utils/isValidEmail";
// @ts-ignore
import BlockContent from "@sanity/block-content-to-react";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import PrmService, { PrmUserData } from "web-common/src/services/PrmService";
import { isValidZipCode } from "../../utils/isValidZipCode";
import { isValidDob } from "../../utils/isValidDob";
import { event59 } from "../../analytics/event59";
import "./styles.scss";
import { IconError } from "../../images/icons/iconError";
import { FormCheckbox } from "../../templates/NewsletterSignUp";
import { RichTextSerializers } from "web-common/src/utils/richTextSerializers";
import Dropdown from "react-bootstrap/Dropdown";
import RichText from "web-common/src/components/RichText";

export type Gender = { genderPlaceholder: string; genderFieldId: string };

export type NewsletterSignUpFormInterface = {
  data: {
    headline: {
      primaryText: string;
      secondaryText?: string;
      tertiaryText?: string;
    };
    showDobField: boolean;
    showZipCodeField: boolean;
    checkboxes: FormCheckbox[];
    additionalFields?: {
      questionText: string;
      questionId: string;
      answer: {
        answerText: string;
        answerId: string;
      }[];
    }[];
    genderSelect: Gender[];
    _rawFooterNotice?: PortableText;
    successfulSignUpMessage: string;
  };
  language?: string;
} & SanityCtaLabelsInterface &
  SanityFormsLabelsInterface &
  SanityErrorMessagesLabelsInterface;

export const NewsletterSignUpForm: FunctionComponent<NewsletterSignUpFormInterface> = ({
  data,
  ctaLabels,
  formsLabels,
  errorMessages,
  language
}) => {
  const { prmApiKey, prmApiUrl, prmBrandCode, prmCampaignId, isoLanguage, isoCountry, extendedProfileApiUrl } =
    useSiteMetadata(language);

  const prmHeaders = {
    isoLanguage: isoLanguage,
    isoCountry: isoCountry,
    brandCode: prmBrandCode,
    campaignId: prmCampaignId
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [email, setEmail] = useState("");
  const [dob, setDob] = useState("");
  const [genderForm, setGenderForm] = useState("");
  const [genderPlaceholder, setGenderPlaceholder] = useState("");
  const [checkboxValues, setCheckboxValues] = useState<{ [index: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [checkboxErrorMessage, setCheckboxErrorMessage] = useState("");
  const [completionMessage, setCompletionMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleInputChange = (
    set: (value: string) => void,
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    set(event.target.value);
  };

  let params = typeof window !== "undefined" ? new URLSearchParams(window.location.search) : null;
  const utm_source = params?.get("utm_source");
  const utm_campaign = params?.get("utm_campaign");

  const handleCheckboxChange = (name: string, checked: boolean) => {
    setCheckboxValues(value => {
      value[name] = checked;

      return value;
    });
  };

  const handleLocalePlaceholder = (isoCountry: string) => {
    if (isoCountry === "CA") {
      return "(yyyy-mm-dd)";
    } else if (isoCountry === "BR") {
      return "(dd/mm/aaaa)";
    } else {
      return "(mm/dd/yyyy)";
    }
  };

  const handleGenderSelect = (e: any) => {
    const genderForm = e.split("-")[0];
    const genderPlaceholder = e.split("-")[1];
    setGenderForm(genderForm);
    setGenderPlaceholder(genderPlaceholder);
  };

  return (
    <>
      {completionMessage ? (
        <Row>
          <Col lg={{ span: 6, offset: 3 }}>
            <p aria-live="assertive" className="completion-message">
              {completionMessage}
            </p>
          </Col>
        </Row>
      ) : (
        <form className="newsletter-sign-up-form" onSubmit={event => handleSubmit(event)} ref={formRef} noValidate>
          <Row>
            <Col lg={{ span: 6, offset: 3 }}>
              <div className="questions-container">
                <div>
                  <label htmlFor="first-name" className={`${submitted && (firstName ? "valid" : "invalid")}`}>
                    <input
                      id="first-name"
                      data-testid="first-name"
                      className="input-line"
                      type="text"
                      placeholder={formsLabels?.firstName}
                      onChange={event => handleInputChange(setFirstName, event)}
                      required
                      aria-required="true"
                    />
                    *
                    {submitted && !firstName && (
                      <p className="error" aria-live="assertive">
                        <IconError />
                        {errorMessages?.validFirstName}
                      </p>
                    )}
                  </label>
                </div>
                <div>
                  <label htmlFor="last-name" className={`${submitted && (lastName ? "valid" : "invalid")}`}>
                    <input
                      id="last-name"
                      data-testid="last-name"
                      className="input-line"
                      type="text"
                      placeholder={formsLabels?.lastName}
                      onChange={event => handleInputChange(setLastName, event)}
                      required
                      aria-required="true"
                    />
                    *
                    {submitted && !lastName && (
                      <p className="error" aria-live="assertive">
                        <IconError />
                        {errorMessages?.validLastName}
                      </p>
                    )}
                  </label>
                </div>
                {data.showZipCodeField && (
                  <div>
                    <label
                      htmlFor="zip-code"
                      className={` ${
                        (zipCode || submitted) && (isValidZipCode(zipCode, isoCountry) ? "valid" : "invalid")
                      }`}
                    >
                      <input
                        id="zip-code"
                        data-testid="zip-code"
                        className="input-line"
                        type="text"
                        placeholder={formsLabels?.zipCode}
                        onChange={event => handleInputChange(setZipCode, event)}
                        required
                        aria-required="true"
                      />
                      *
                      {(zipCode || submitted) && !isValidZipCode(zipCode, isoCountry) && (
                        <p className="error" aria-live="assertive">
                          <IconError />
                          {errorMessages?.validZipCode}
                        </p>
                      )}
                    </label>
                  </div>
                )}
                <div>
                  <label
                    htmlFor="email"
                    className={` ${(email || submitted) && (isValidEmail(email) ? "valid" : "invalid")}`}
                  >
                    <input
                      id="email"
                      data-testid="email"
                      className="input-line"
                      type="email"
                      placeholder={formsLabels?.email}
                      onChange={event => handleInputChange(setEmail, event)}
                      required
                      aria-required="true"
                    />
                    *
                    {(email || submitted) && !isValidEmail(email) && (
                      <p className="error" aria-live="assertive">
                        <IconError />
                        {errorMessages?.validEmail}
                      </p>
                    )}
                  </label>
                </div>
              </div>
              {data.showDobField && (
                <div>
                  <label
                    htmlFor="date-of-birth"
                    className={` ${(dob || submitted) && (isValidDob(dob, isoCountry) ? "valid" : "invalid")}`}
                  >
                    <input
                      id="date-of-birth"
                      data-testid="dob"
                      className="input-line"
                      type="text"
                      placeholder={`${formsLabels?.dob} ${handleLocalePlaceholder(isoCountry)}`}
                      onChange={event => handleInputChange(setDob, event)}
                      required
                      aria-required="true"
                    />
                    *
                    {(dob || submitted) && !isValidDob(dob, isoCountry) && (
                      <p className="error" aria-live="assertive">
                        <IconError />
                        {errorMessages?.validDateOfBirth}
                      </p>
                    )}
                  </label>
                </div>
              )}
              {data.genderSelect.length > 0 && (
                <div className="gender-select">
                  <Dropdown onSelect={handleGenderSelect}>
                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="signup-dropdown">
                      {genderPlaceholder || formsLabels?.gender}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="signup-dropdown-menu">
                      {data.genderSelect.map(({ genderPlaceholder, genderFieldId }) => (
                        <Dropdown.Item eventKey={`${genderFieldId}-${genderPlaceholder}`}>
                          {genderPlaceholder}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                    *
                    {submitted && !genderForm && (
                      <p className="error" aria-live="assertive">
                        <IconError />
                        {errorMessages?.validGender}
                      </p>
                    )}
                  </Dropdown>
                </div>
              )}
              {data.additionalFields &&
                data.additionalFields.map((field, index) => (
                  <div className="t-and-c additional-fields" key={index}>
                    <fieldset aria-labelledby="additional-fields">
                      <legend id="additional-fields">{field.questionText}</legend>
                      {field.answer.map((checkbox, index) => (
                        <div key={`additional-checkbox-${index}`} className="checkbox">
                          <input
                            type="checkbox"
                            name={checkbox.answerText}
                            onChange={event => handleCheckboxChange(checkbox.answerId, event.target.checked)}
                            id={checkbox.answerId}
                          />
                          <label htmlFor={checkbox.answerId} className="checkbox-label">
                            {checkbox.answerText}
                          </label>
                        </div>
                      ))}
                    </fieldset>
                  </div>
                ))}
              {data.checkboxes && (
                <div className="t-and-c">
                  {data.checkboxes.map((checkbox, index) => (
                    <div
                      key={`t-and-c-checkbox-${index}`}
                      className="checkbox"
                      data-testid={`t-and-c-checkbox-${index}`}
                    >
                      {checkbox.hideCheckbox ? (
                        <BlockContent blocks={checkbox._rawLabel} serializers={RichTextSerializers()} />
                      ) : (
                        <>
                          <input
                            type="checkbox"
                            name={checkbox.type}
                            onChange={event => handleCheckboxChange(checkbox.type, event.target.checked)}
                            id={checkbox.type}
                            required={checkbox.required}
                          />
                          <label htmlFor={checkbox.type} className="checkbox-label">
                            <BlockContent blocks={checkbox._rawLabel} serializers={RichTextSerializers()} />
                          </label>
                          {checkboxErrorMessage && !checkboxValues[checkbox.type] && (
                            <p className="checkbox-error" aria-live="assertive">
                              <IconError />
                              {checkboxErrorMessage}
                            </p>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {data._rawFooterNotice && (
                <div className="footer-notice">
                  <RichText data={data._rawFooterNotice} />
                </div>
              )}
              <div className="submit-section">
                <Button type="submit" variant="outline-light" className="submit-button">
                  {ctaLabels?.signUpForNewsletter}
                </Button>
                {isLoading && <p>{formsLabels?.loading}</p>}
                {errorMessage && (
                  <p className="error" aria-live="assertive">
                    <IconError />
                    {errorMessage}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </form>
      )}
    </>
  );

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const checkboxes = window?.document.querySelectorAll("checkbox-label");

    for (const checkbox of checkboxes) {
      checkbox.classList.remove("highlited-checkbox");
    }

    for (const checkbox of data.checkboxes) {
      if (checkbox.required && !checkboxValues[checkbox.type]) {
        const firstInvalidCheckbox = window?.document.querySelectorAll(".checkbox-label")[0];
        firstInvalidCheckbox?.scrollIntoView({ behavior: "smooth", block: "center" });
        firstInvalidCheckbox.classList.add("highlited-checkbox");

        setCheckboxErrorMessage(errorMessages?.requiredField || "");
        return;
      }
    }

    try {
      await setSubmitted(true);

      let isFormValid = formRef.current?.checkValidity() && isValidEmail(email);

      if (data.showZipCodeField && !isValidZipCode(zipCode, isoCountry)) isFormValid = false;
      if (data.showDobField && !isValidDob(dob, isoCountry)) isFormValid = false;

      if (!isFormValid) {
        const firstInvalidField = window?.document.querySelector(".newsletter-sign-up-form label.invalid");

        setErrorMessage(errorMessages?.validForm as string);

        firstInvalidField?.scrollIntoView({ behavior: "smooth", block: "start" });
        firstInvalidField?.querySelector("input")?.focus();
      } else {
        const prmPayload: PrmUserData = {
          firstName: firstName,
          lastName: lastName,
          email: email,
          questionAndAnswers: []
        };

        data.checkboxes.forEach(checkbox => {
          prmPayload[checkbox.type] = checkbox.hideCheckbox ? true : checkboxValues[checkbox.type];
        });

        if (data.additionalFields) {
          prmPayload.questionAndAnswers = data.additionalFields.map((field, index) => {
            return {
              answerId: field.answer
                .filter(answer => checkboxValues[answer.answerId]) // Filtra apenas os answer com checkboxValues[answer.answerId] sendo true
                .map(answer => Number(answer.answerId)),
              questionId: Number(field.questionId)
            };
          });
        }

        if (data.showDobField) {
          if (isoCountry === "CA") {
            prmPayload.dateOfBirth = dob;
          } else if (isoCountry === "BR") {
            let d = dob.split("/")[0];
            let m = dob.split("/")[1];
            let y = dob.split("/")[2];
            let formatedDob = `${y}-${m}-${d}`;
            prmPayload.dateOfBirth = formatedDob;
          } else {
            prmPayload.dateOfBirth = new Date(dob).toISOString();
          }
        }
        if (data.showZipCodeField) prmPayload.postCode = zipCode;
        if (data.genderSelect.length > 0) prmPayload.gender = genderForm;

        const response = await PrmService.createPrmProfile(prmApiKey, prmApiUrl, prmHeaders, prmPayload);

        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);
        const { UnileverId } = response;
        document.cookie = `UnileverId=${UnileverId}; expires=${expireDate.toUTCString()} `;
        if (typeof jest === "undefined") event59();
        setCompletionMessage(data.successfulSignUpMessage);
      }
    } catch {
      setErrorMessage(errorMessages?.signUpError as string);
      setIsLoading(false);
    }
  }
};

export default NewsletterSignUpForm;
