import * as React from "react";
import Layout from "../../components/Layout";
import SEO from "../../components/Seo";
import { Col, Container, Row } from "react-bootstrap";
import { graphql, PageProps } from "gatsby";
import { LocalizedContextInterface, MultiLineHeadline, PortableText, Seo } from "../../types/SanityTypes";
import { LocalisedPageContext } from "../../types/PageTypes";
import NewsletterSignUpForm from "../../components/NewsletterSignUpForm";
import StructuredData from "../../components/StructuredData";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import "./styles.scss";

export type ProductCategory = { categoryName: string; epsilonAnswerId: string };

export type FormCheckbox = {
  type: "sureCommunicationsOptIn" | "otherCommunicationsOptIn" | "termsAndConditionsAgreed";
  _rawLabel: PortableText;
  required: boolean;
  defaultChecked: boolean;
  hideCheckbox: boolean;
};

export type gender = { genderPlaceholder: string; genderFieldId: string };

export type NewsletterSignUpProps = {
  newsletter: {
    headline: MultiLineHeadline;
    interestProductsLabel: {
      questionText: string;
      questionId: string;
    };
    epsilonSurveyId: string;
    interestProductCategories: ProductCategory[];
    genderSelect: gender[];
    checkboxes: FormCheckbox[];
    _rawPrivacyCookies: PortableText;
    _rawOffersUnilever: PortableText;
    _rawOffersBrand: PortableText;
    _rawTermsOfUse: PortableText;
    successfulSignUpMessage: string;
    seo: Seo;
  };
} & LocalizedContextInterface;

type NewsletterSignUpPageContext = LocalisedPageContext;

export const query = graphql`
  query NewsletterSignUpPage($_id: String, $language: String, $market: String) {
    newsletter: sanityNewsletterSignUp(_id: { eq: $_id }) {
      id
      headline {
        primaryText
        secondaryText
        tertiaryText
      }
      checkboxes {
        type
        _rawLabel(resolveReferences: { maxDepth: 8 })
        required
        defaultChecked
        hideCheckbox
      }
      additionalFields {
        questionText
        questionId
        answer {
          answerText
          answerId
        }
      }
      showDobField
      showZipCodeField
      genderSelect {
        genderPlaceholder
        genderFieldId
      }
      _rawFooterNotice
      successfulSignUpMessage
      seo {
        metaDescription
        metaTitle
        jsonld
      }
    }
    sanityLabels(i18n_lang: { eq: $language }, market: { eq: $market }) {
      ...CtaLabelsFieldsFull
      ...FormsLabelsFieldsFull
      ...ErrorMessagesLabelsFieldsFull
    }
    ...LocalizedContext
  }
`;

const NewsletterSignUp = (props: PageProps<NewsletterSignUpProps, NewsletterSignUpPageContext>) => {
  const newsletterSignUp = props.data.newsletter;
  const sanityLabels = props.data.sanityLabels;
  const language = props.pageContext.language;
  const url = props.data.sanityNewsletterSignUp;
  const { htmlLang } = useSiteMetadata(language);

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={newsletterSignUp.seo.metaTitle}
      pageType={"newsletterSignUp"}
    >
      <StructuredData
        type={"SingUpPage"}
        language={htmlLang}
        slug={`/${url.slug.current}/`}
        name={newsletterSignUp.seo.metaTitle}
        description={newsletterSignUp.seo.metaDescription}
        customData={newsletterSignUp.seo.jsonld}
      />
      <SEO title={newsletterSignUp.seo.metaTitle} description={newsletterSignUp.seo.metaDescription} />
      <div className="page_newsletter-sign-up">
        <Container fluid>
          <Row>
            <Col>
              <h1>
                <span className="heading-line-1">{newsletterSignUp.headline.primaryText}</span>
                {newsletterSignUp.headline.secondaryText && (
                  <span className="heading-line-2">{newsletterSignUp.headline.secondaryText}</span>
                )}
                {newsletterSignUp.headline.tertiaryText && (
                  <span className="heading-line-1">{newsletterSignUp.headline.tertiaryText}</span>
                )}
              </h1>
              <NewsletterSignUpForm
                data={newsletterSignUp}
                ctaLabels={sanityLabels?.ctaLabels}
                formsLabels={sanityLabels?.formsLabels}
                errorMessages={sanityLabels?.errorMessages}
                language={props.pageContext.language}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default NewsletterSignUp;
