export const isValidDob = (dob: string, country = "") => {
  switch (country) {
    case "CA":
      return /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/.test(dob);
    case "BR":
      return /(0[1-9]|[12][0-9]|3[01])[/](?:0[1-9]|1[012])[/](?:19\d{2}|20[01][0-9]|2022)/.test(dob);
    default:
      return /(?:0[1-9]|1[012])[/](0[1-9]|[12][0-9]|3[01])[/](?:19\d{2}|20[01][0-9]|2022)/.test(dob);
  }
};
