export const isValidZipCode = (zipCode: string, country: string = 'US') => {
  switch (country) {
    case 'BR':
      return /^\d{5}-?\d{3}$/.test(zipCode);

    case 'CA':
      return /[ABCEGHJKLMNPRSTVXY][0-9][ABCEGHJKLMNPRSTVWXYZ] ?[0-9][ABCEGHJKLMNPRSTVWXYZ][0-9]/.test(zipCode);

    case 'US':
    default:
      return /^\d{5}(?:[-\s]\d{4})?$/.test(zipCode);
  }
};
